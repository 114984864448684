<script setup lang="ts">
import { onUnmounted, ref, watch } from 'vue'

type Props = {
    show: boolean,
    hiddenCloseIcon?: boolean,
    class?: string,
    id?: string,
    isDisabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    id: '',
    class: '',
    isDisabled: false
})

const emit = defineEmits(['close'])

const isModalFullyOpen = ref(false) 

const modalWrap = ref()

const closeModalOnClickOutside = (event: MouseEvent): void => {
    if (!modalWrap.value.contains(event.target as Node) && isModalFullyOpen.value) {
        emit('close')
    }
}

const handleEscKey = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' && isModalFullyOpen.value) {
        emit('close')
    }
}

const onTransitionEnd = (): void => {
    if (props.show) {
        isModalFullyOpen.value = true
    }
}

watch(() => props.show, (show) => {
    if (show) {
        document.body.classList.add('modal-open')
        window.addEventListener('click', closeModalOnClickOutside)
        window.addEventListener('keydown', handleEscKey)
    } else {
        isModalFullyOpen.value = false
        document.body.classList.remove('modal-open')
        window.removeEventListener('click', closeModalOnClickOutside)
        window.removeEventListener('keydown', handleEscKey)
    }
})

onUnmounted(() => {
    document.body.classList.remove('modal-open')
    window.removeEventListener('click', closeModalOnClickOutside)
    window.removeEventListener('keydown', handleEscKey)
})

</script>

<template>
    <Teleport to="body" :disabled="isDisabled">
        <div :class="[ props.class ]">
            <transition name="slide-fade" @after-enter="onTransitionEnd">
                <div
                    v-if="show"
                    :id="props.id"
                    class="app-modal"
                >
                    <div class="app-modal-dialog">
                        <div ref="modalWrap" class="app-modal-dialog-wrap">
                            <slot />
          
                            <button
                                v-if="!hiddenCloseIcon"
                                type="button"
                                class="app-modal-close-btn"
                                @click="emit('close')"
                            >
                                <slot name="close-icon" />
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </Teleport>
</template>
  

  
<style scoped lang="scss">
.slide-fade-enter-active, .slide-fade-leave-active {
    transition: transform 0.4s ease-out, opacity .5s ease-out;

  .app-modal-dialog {
    transition: transform 0.4s ease-out, opacity .5s ease-out;
  }
}

.slide-fade-enter-from {
  opacity: 0;

  .app-modal-dialog {
    transform: translateY(-60px);
    opacity: 0;
  }
}

.slide-fade-enter-to {
  opacity: 1;

  .app-modal-dialog {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-fade-leave-from {
  opacity: 1;

  .app-modal-dialog {
    transform: translateY(0);
    opacity: 1;

  }
}
.slide-fade-leave-to {
  opacity: 0;

  .app-modal-dialog {
    transform: translateY(-60px);
    opacity: 0;
  }
}

.app-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;


  &-dialog {
    position: relative;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    &-wrap {
      position: relative;
      width: 100%;
    }
  }

  &-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    border: 0;

    &:focus {
      outline: none;
    }
  }
}
</style>